import { HelmetProvider } from "react-helmet-async";
import Dashboard from "./component/Dashboard/Dashboard";
import MyBeShowNavbar from "./component/MyBeShowNavbar/MyBeShowNavbar";
import React from "react";

function App() {
  return (
    <HelmetProvider>
      <MyBeShowNavbar>
        <Dashboard />
      </MyBeShowNavbar>
    </HelmetProvider>
  );
}

export default App;
