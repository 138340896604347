import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./media.css";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// bootstrap
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import { BrowserRouter } from "react-router-dom";
const sessionIdTemp = localStorage.getItem("session_id");
if (sessionIdTemp == null) {
  const sessionId = uuidv4(); // Generate a unique session ID
  localStorage.setItem("session_id", sessionId);
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
