import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import loginMap from "../assets/images/logo.jpg";
import "../admin-login/Login.css";
import style from "./Login.module.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Modal, Button } from "react-bootstrap";
import {
  API_BASE_URL,
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
} from "../../Constant/Global";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AdminLogin = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [showOtpVerification, setShowOtpVerification] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [serverOtp, setServerOtp] = useState("");

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem("info") != null) {
      console.log("User is already logged in");
      navigate("/");
    }
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const loginData = async (values, { resetForm }) => {
    try {
      const formData = new FormData();
      formData.append("email", values.email);
      formData.append("password", values.password);
      const response = await axios.post(
        API_BASE_URL + "vendor/login",
        formData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      resetForm();
      if (response.data.status) {
        sessionStorage.setItem("info", JSON.stringify(response.data.data));
        navigate("/");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log(error.response.data.message);
    }
  };

  // send otp
  const sendOtp = async (email) => {
    try {
      const finalData = {
        email: email,
      };
      const response = await axios.post(
        API_BASE_URL + "vendor/forget-password-otp",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setServerOtp(response.data.data);
        setUserEmail(email);
        setShowForgotPassword(false);
        setShowOtpVerification(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log("error occurred sending otp", error);
    }
  };

  // Handle OTP verification
  const verifyOtp = async () => {
    try {
      if (otp === "") {
        toast.error("Please Enter OTP!");
      } else if (parseInt(otp) !== parseInt(serverOtp)) {
        toast.error("Please Enter Valid OTP!");
      } else {
        toast.success("OTP verified.");
        setOtpVerified(true);
        setShowOtpVerification(false);
        setShowResetPassword(true);
      }
    } catch (error) {
      toast.error("Error verifying OTP.");
    }
  };

  // Handle password reset
  const resetPassword = async (values) => {
    try {
      const finalData = {
        email: userEmail,
        new_password: values.newPassword,
      };
      const response = await axios.post(
        API_BASE_URL + "vendor/reset-password",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setShowResetPassword(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Error resetting password.");
    } finally {
      setOtp("");
      setServerOtp("");
    }
  };
  return (
    <React.Fragment>
      <Helmet>
        <title>Seller login | Goods24</title>
      </Helmet>
      <section className="loginMainSection">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="main-section ps-0 pe-0 d-flex">
              <div className="col-lg-8 col-md-12 d-flex justify-content-center align-items-center">
                <div className={`${style.map_sec}`}>
                  <h1 className={`${style.login_left_text}`}>GOODS24 Seller</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 login_right_sec">
                <div className="login-sec">
                  <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={loginData}
                  >
                    {({ touched, errors }) => (
                      <Form className={`${style.form}`}>
                        {/* <div className="login_right_logo_">
                          <img
                            src={loginMap}
                            alt="login"
                            className={`${style.login_logo} img-fluid`}
                            style={{ height: "50px", width: "142px" }}
                          />
                        </div> */}
                        <h1 className="login-text text-center mb-4">Login</h1>
                        <div className={`${style.password_sec}`}>
                          <Field
                            type="text"
                            name="email"
                            placeholder="Email"
                            className={`${style.username} ${style.input} ${
                              touched.email && errors.email
                                ? `${style.invalid}`
                                : ""
                            }`}
                            autoComplete="off"
                          />
                          <ErrorMessage
                            className={`text-danger ${style.error_text}  text-start`}
                            name="email"
                            component="div"
                          />
                        </div>

                        <div className={`${style.password_sec}`}>
                          <Field
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="Password"
                            autoComplete="off"
                            className={`${style.password} ${style.input} ${
                              touched.password && errors.password
                                ? `${style.invalid}`
                                : ""
                            }`}
                          />
                          <button
                            type="button"
                            className={`${style.password_toggle_button}`}
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? (
                              <FaEye color="#a1a1a1" />
                            ) : (
                              <FaEyeSlash color="#a1a1a1" />
                            )}
                          </button>
                          <ErrorMessage
                            className={`text-danger ${style.error_text}  text-start`}
                            name="password"
                            component="div"
                          />
                        </div>
                        <div className="forgotDiv">
                          <Link
                            to="#"
                            onClick={() => setShowForgotPassword(true)}
                          >
                            Forgot Password?
                          </Link>
                        </div>
                        <div>
                          <button className={`${style.btn}`} type="submit">
                            Login
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <div className="develope_bottom_text">
                    <p>
                      Design & Developed By{" "}
                      <Link
                        to="https://www.microdynamicsoftware.com/"
                        target="_blank"
                      >
                        Microdynamic Software Pvt.Ltd
                      </Link>{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position={"top-right"}
          autoClose={2000}
          pauseOnHover={false}
          closeOnClick={true}
        />

        {/* Forgot Password Modal */}
        <Modal
          show={showForgotPassword}
          onHide={() => setShowForgotPassword(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Forgot Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ email: "" }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email("Invalid email format")
                  .required("Email is required"),
              })}
              onSubmit={(values) => sendOtp(values.email)}
            >
              <Form>
                <div className="form-group">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <Button type="submit" className="mt-3">
                  Send OTP
                </Button>
              </Form>
            </Formik>
          </Modal.Body>
        </Modal>

        {/* OTP Verification Modal */}
        <Modal
          show={showOtpVerification}
          onHide={() => setShowOtpVerification(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Verify OTP</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <input
                type="text"
                placeholder="Enter OTP"
                className="form-control"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                maxLength={4}
              />
              <Button className="mt-3" onClick={verifyOtp}>
                Verify OTP
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Reset Password Modal */}
        <Modal
          show={showResetPassword}
          onHide={() => setShowResetPassword(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Reset Password</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Formik
              initialValues={{ newPassword: "", confirmNewPassword: "" }}
              validationSchema={Yup.object({
                newPassword: Yup.string()
                  .min(8, "Password must be at least 8 characters")
                  .matches(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character"
                  )
                  .required("New Password is required"),
                confirmNewPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                  .required("Confirm your new password"),
              })}
              onSubmit={resetPassword}
            >
              <Form>
                <div className="form-group custom-input ">
                  <Field
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    className="form-control "
                  />
                  <button
                    type="button"
                    className={`${style.password_toggle_buttonNew}`}
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <FaEye color="#a1a1a1" />
                    ) : (
                      <FaEyeSlash color="#a1a1a1" />
                    )}
                  </button>
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <div className="form-group custom-input position-relative">
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmNewPassword"
                    placeholder="Confirm New Password"
                    className="form-control "
                  />
                  <button
                    type="button"
                    className={`${style.password_toggle_buttonNew}`}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <FaEye color="#a1a1a1" />
                    ) : (
                      <FaEyeSlash color="#a1a1a1" />
                    )}
                  </button>
                  <ErrorMessage
                    name="confirmNewPassword"
                    component="div"
                    className="text-danger"
                  />
                </div>
                <Button type="submit" className="mt-3">
                  Reset Password
                </Button>
              </Form>
            </Formik>
          </Modal.Body>
        </Modal>
      </section>
    </React.Fragment>
  );
};

export default AdminLogin;
