import React from "react";
import style from "./Invoice.module.css";

import PrintIcon from "@mui/icons-material/Print";

import { useParams } from "react-router-dom";
import numberToWords from "number-to-words";
import axios from "axios";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
const Invoice = () => {
  let totalCGST = 0;
  let totalSGST = 0;
  let totalIGST = 0;
  const [orderDetailsData, setOrderDetailsData] = useState();
  const [userDetailsData, setUserDetailsData] = useState([]);
  const isSpecificPage = true;
  const [orderProductData, setOrderProductData] = useState();
  const [orderAddressData, setOrderAddressData] = useState("");
  const { id } = useParams();

  // date in address
  let defaultDate = new Date();
  defaultDate.setDate(defaultDate.getDate());

  // order details

  useEffect(() => {
    const handleOrderDetails = async () => {
      try {
        const response = await axios.get(
          API_BASE_URL + `vendor/order/order-detail/${id}`,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          setOrderAddressData(response.data.data);
          setOrderDetailsData(response.data.data.order_details);
          setUserDetailsData(response.data.data.user_details);
          setOrderProductData(response.data.data.order_details);
        }
      } catch (error) {
        console.log("error cancel order", error);
        throw error;
      }
    };
    handleOrderDetails();
  }, [id]);

  function calculatePrice(lengthOfProduct, widthOfProduct, unitPrice) {
    return (
      (parseFloat(Math.ceil(lengthOfProduct)) || 0) *
      (parseFloat(Math.ceil(widthOfProduct)) || 0) *
      (parseFloat(unitPrice) || 0)
    );
  }

  const subTotal = (price, qty) => {
    return parseFloat(price) * parseFloat(qty);
  };

  const handlePrintClick = () => {
    const elementsToHide = document.querySelectorAll(
      ".btn_invoice, .navbar , .top-navbar, .footer-main, .invoice_text_title"
    );

    // Hide elements before printing
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    // Add print-specific styles
    const style = document.createElement("style");
    style.innerHTML = `
      @media print {
        body {
          size: A4 portrait; /* You can use 'portrait' instead of 'landscape' if needed */
        }
        /* Add more print styles as needed */
      table {
        page-break-inside: auto;
      }
      th, td {
        page-break-inside: avoid;
      }
      @page {
        margin-top: 10mm; /* Adjust the value as needed */
      }
      .stamp_size{
        margin-left:0px !important;
      }
  
        /* Add more print styles as needed */
      }
    `;
    document.head.appendChild(style);

    // Trigger the print dialog
    window.print();

    // Show elements back after printing
    elementsToHide.forEach((element) => {
      element.style.display = "block";
    });

    // Remove the print-specific styles
    document.head.removeChild(style);

    // Reload the page
    window.location.reload();
  };

  function capitalizeWords(input) {
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const totalPrice = orderProductData?.price * orderProductData?.qty || 0;

  const totalPriceInWords = numberToWords.toWords(totalPrice);

  const totalPriceInWordsCapitalized = capitalizeWords(totalPriceInWords);

  // generate pdf

  const generatePDF = () => {
    const input = document.getElementById("invoice-content");

    // Elements to hide during PDF generation
    const elementsToHide = document.querySelectorAll(
      ".btn_invoice, .navbar, .top-navbar, .footer-main, .invoice_text_title"
    );
    elementsToHide.forEach((element) => {
      element.style.display = "none";
    });

    // Use html2canvas to capture the content of the input element
    html2canvas(input, {
      scale: 2, // Increase scale for better quality (optional)
      useCORS: true, // Handle cross-origin images
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Define margins and page size
      const imgWidth = 210; // A4 page width in mm
      const pageHeight = 297; // A4 page height in mm
      const margin = 10; // Margin in mm

      // Calculate image height maintaining aspect ratio
      const imgHeight =
        (canvas.height * (imgWidth - margin * 2)) / canvas.width;

      let pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4", // Standard A4 page size
      });

      let position = margin; // Initial Y position with margin

      if (imgHeight > pageHeight - margin * 2) {
        // Split the content across multiple pages if it exceeds one page height
        let remainingHeight = canvas.height;
        while (remainingHeight > 0) {
          pdf.addImage(
            imgData,
            "JPEG",
            margin, // X position with margin
            position, // Y position with margin
            imgWidth - margin * 2, // Width considering margin
            imgHeight
          );
          remainingHeight -= pageHeight;
          if (remainingHeight > 0) pdf.addPage(); // Add a new page if more content
        }
      } else {
        // Single page content with margins
        pdf.addImage(
          imgData,
          "JPEG",
          margin, // X position with margin
          margin, // Y position with margin
          imgWidth - margin * 2, // Width considering margin
          imgHeight
        );
      }

      pdf.save("invoice.pdf");
    });

    // Restore visibility of hidden elements
    elementsToHide.forEach((element) => {
      element.style.display = "";
    });
  };
  return (
    <>
      <Helmet>
        <title>Order Details | Goods24</title>
        {/* Conditionally set the viewport scale to 0.5 */}
        {isSpecificPage && (
          <meta
            name="viewport"
            content="width=device-width, initial-scale=0.5"
          />
        )}
      </Helmet>
      <div
        id="invoice-content"
        className={`container bg-white ${style.main_invoice}`}
      >
        <div className="row">
          <header>
            <h5 className={`fw-bold fs-5 ${style.invoice_title}`}>Invoice</h5>
          </header>
          <div className="col-lg-12">
            <table className={style.table_main}>
              <tr>
                <th
                  className={`ms-2 border-bottom-0 text-center ${style.border_left}`}
                  colSpan={1}
                >
                  <img
                    src={require("../../component/assets/images/logo1.png")}
                    height="40"
                    alt="Company Logo"
                  />
                </th>
                <th className="border-bottom-0" colSpan={9}>
                  <p className="bd_weight fs-6 fw-bold ">
                    {orderAddressData?.company_name}{" "}
                  </p>
                  <p className="bd_weight">
                    {orderAddressData?.company_address}
                  </p>
                  <div className={`${style.invoice_col}`}>
                    <p> Phone: {orderAddressData?.company_phone} </p>
                    {orderAddressData?.gst_no !== "" ? (
                      <p>GST:{orderAddressData?.gst_no} </p>
                    ) : (
                      ""
                    )}
                  </div>
                  <p>Email: {orderAddressData?.company_email}</p>
                  <p>Website : {orderAddressData?.website}</p>
                </th>
              </tr>
            </table>
            <table className={`mt-0 border-bottom-0 ${style.table_main}`}>
              <tr>
                <td className={style.border_left} colSpan={3}>
                  Bill To
                  <p className="fw-bold">{orderProductData?.receiver_name}</p>
                  <p>{orderProductData?.delivery_address}</p>
                  <p>Phone: {orderProductData?.receiver_mobile_no}</p>
                </td>
                <th
                  colSpan={8}
                  className={`${style.border_left} ${style.invoice_background}`}
                  id={style.invoice_form}
                  rowSpan={2}
                >
                  <p className="text-start mt-4">
                    Invoice No : {orderProductData?.sale_item_id}
                  </p>
                  <p className="text-start">
                    Date:{" "}
                    {orderProductData?.on_date &&
                      new Date(orderProductData?.on_date)
                        .toLocaleDateString("en-GB", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric",
                        })
                        .replace(/\//g, "-")}
                  </p>
                  <p className="text-start">
                    Payment Method : {orderProductData?.payment_method}
                  </p>
                </th>
              </tr>
              <tr></tr>
              <tr className={`${style.total_row} ${style.width_row}`}>
                <td className={style.border_left}>#</td>
                <td className={style.border_left}>Product name</td>

                <td className={style.border_left}>Quantity</td>
                <td className={style.border_left}>Rate</td>
                <td className={style.border_left}>Disc.</td>
                <td className={style.border_left}>Price</td>

                <td className={style.border_left}>IGST</td>
                <td className={style.border_left}>CGST</td>
                <td className={style.border_left}>SGST</td>
                <td className={style.border_left}>Amount</td>
              </tr>

              <tr
                key={`${orderProductData?.product_id}`}
                className="border-bottom-0"
              >
                <td className={`${style.border_left} ${style.idColumn}`}>1</td>
                <td className={style.border_left}>
                  {orderProductData?.product_name}
                  {orderProductData?.size}
                  {parseFloat(orderProductData?.tax) > 0 && (
                    <>
                      <br />
                      <span>GST(Included): {orderProductData?.tax}%</span>
                    </>
                  )}
                </td>

                <td className={style.border_left}>
                  {orderProductData?.qty}{" "}
                  {orderProductData?.unit && orderProductData?.unit}
                </td>
                <td
                  className={style.border_left}
                  style={{ fontFamily: "sans-serif" }}
                >
                  {parseFloat(orderProductData?.mrp).toFixed(2)}
                </td>
                <td
                  className={style.border_left}
                  style={{ fontFamily: "sans-serif" }}
                >
                  {parseFloat(
                    orderProductData?.mrp - orderProductData?.price
                  ).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  {orderProductData?.price.toFixed(2)}
                </td>
                <td className={style.border_left}>
                  {parseFloat(orderProductData?.isgt || 0).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  {parseFloat(orderProductData?.csgt || 0).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  {parseFloat(orderProductData?.ssgt || 0).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  {subTotal(
                    orderProductData?.price,
                    orderProductData?.qty
                  ).toFixed(2)}
                </td>
              </tr>

              <tr></tr>
              <tr>{/* <td colSpan={5}></td>  */}</tr>
            </table>

            <table
              className={`mt-0 border-top-0 ${style.table_main} ${style.table_main1}`}
            >
              <tr className={style.tax_row}>
                <td className={style.border_left}>Taxable Amount</td>
                <td className={style.border_left}>IGST</td>
                <td className={style.border_left}>CGST</td>
                <td className={style.border_left}>SGST</td>
                <td className={style.border_left} colSpan={5}>
                  Tax Amount:
                </td>
              </tr>

              <tr>
                <td className={style.border_left}>
                  ₹{" "}
                  {parseFloat(orderAddressData?.taxableAmount ?? 0).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  ₹ {parseFloat(orderAddressData?.totalIGST ?? 0).toFixed(2)}
                </td>
                <td className={style.border_left}>
                  ₹ {parseFloat(orderAddressData?.totalCGST ?? 0).toFixed(2)}
                </td>
                <td className={style.border_left} rowSpan={1}>
                  ₹ {parseFloat(orderAddressData?.totalSGST ?? 0).toFixed(2)}
                </td>
                <td colSpan={4} style={{ textAlign: "right" }}>
                  ₹ {parseFloat(orderAddressData?.totalTax ?? 0).toFixed(2)}
                </td>
              </tr>

              <tr>
                <td
                  rowSpan={7}
                  colSpan={3}
                  className={`fw-bold  p ${style.border_left}`}
                >
                  Amount in Words : Indian Rupee {totalPriceInWordsCapitalized}
                  <span className="ms-1">Only</span>
                </td>
                {/* <td ></td> */}
                {/* <td
                  className={` fw-bold  ${style.border_left}`}
                  style={{ width: "12%" }}
                >
                  Cart Value
                </td>
                <td
                  colSpan={4}
                  className="fw-bold fs-6"
                  style={{ textAlign: "right" }}
                >
                  ₹{" "}
                  {(
                    parseFloat(orderProductData?.mrp) -
                    parseFloat(orderProductData?.delivery_charge)
                  ).toFixed(2)}
                </td> */}
              </tr>
              {/* <tr>
                <td className={style.border_left}> Shipping</td>
                <td colSpan={4} style={{ textAlign: "right" }}>
                  {orderProductData?.delivery_charge === null ||
                  orderProductData?.delivery_charge === 0
                    ? "Free"
                    : `
                    ₹ ${parseFloat(orderProductData?.delivery_charge).toFixed(
                      2
                    )}
                  `}
                </td>
              </tr> */}
              {/* <tr>
                <td
                  className={` fw-bold  ${style.border_left}`}
                  style={{ width: "12%" }}
                >
                  SubTotal
                </td>
                <td
                  colSpan={4}
                  className="fw-bold fs-6"
                  style={{ textAlign: "right" }}
                >
                  ₹ {parseFloat(orderProductData?.order_amount).toFixed(2)}
                </td>
              </tr> */}
              <tr style={{ fontWeight: "bold" }}>
                <td className={style.border_left}> Net Amount</td>
                <td
                  colSpan={4}
                  className="fs-6"
                  style={{ textAlign: "right", fontWeight: "bold" }}
                >
                  ₹{" "}
                  {subTotal(
                    orderProductData?.price,
                    orderProductData?.qty
                  ).toFixed(2)}
                </td>
              </tr>
              {parseFloat(orderProductData?.wallet_used).toFixed(2) > 0 && (
                <tr style={{ fontWeight: "bold" }}>
                  <td className={style.border_left}> Used Wallet</td>
                  <td colSpan={4} style={{ textAlign: "right" }}>
                    ₹ {parseFloat(orderProductData?.wallet_used).toFixed(2)}
                  </td>
                </tr>
              )}
              {parseFloat(orderProductData?.total_amount) -
                parseFloat(orderProductData?.wallet_used) >
                0 && (
                <tr style={{ fontWeight: "bold" }}>
                  <td className={style.border_left}>
                    {orderProductData?.payment_method === "Cash On Delivery"
                      ? "Payable Amount"
                      : "Paid Amount"}
                  </td>
                  <td colSpan={4} style={{ textAlign: "right" }}>
                    ₹{" "}
                    {(
                      parseFloat(orderProductData?.total_amount) -
                      parseFloat(orderProductData?.wallet_used)
                    ).toFixed(2)}
                  </td>
                </tr>
              )}
            </table>
            <table
              style={{
                border: "1px solid black",
                width: "100%",
                borderTop: "0px",
              }}
            >
              <tr style={{ fontWeight: "bold" }}>
                <td style={{ padding: "10px" }}>
                  <span className="fw-bold"> Terms and Conditions: </span>
                  <p className="">
                    1. Payment is due within 30 days of the invoice date.
                  </p>
                  <p className="">
                    2. Late payments may incur a penalty fee of 5% per month.
                  </p>
                </td>
              </tr>
            </table>
          </div>
          <div className="col-lg-12 mb-5 mt-5">
            <div className="invoice_text d-flex justify-content-end mb-3 "></div>
            <div className="btn_invoice">
              <button
                className="btn btn-success print_invoice_btn"
                style={{ height: "40px" }}
                onClick={generatePDF}
              >
                Download
              </button>
            </div>
            {/* <div className="btn_invoice">
              {orderProductData?.status === 4 ? (
                <button
                  className="btn btn-primary print_invoice_btn"
                  style={{ height: "40px" }}
                  onClick={handlePrintClick}
                >
                  <PrintIcon /> Print Invoice
                </button>
              ) : (
                ""
              )}
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Invoice;
